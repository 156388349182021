<template>

  <table class="table table-sm align-top">
    <thead class="align-top table-info">
    <tr>
      <th scope="col">Дата покупки</th>
      <th scope="col">Студент</th>
      <th scope="col">Автор курса</th>
      <th scope="col">Навзвание курса</th>
      <th scope="col">Стоимость курса</th>
      <th scope="col">Просмотрено уроков</th>
      <th scope="col">Прочее</th>
    </tr>
    </thead>
    <tbody>

    <tr v-show="reportItemsLocalCopy.length == 0">
      <td colspan="10">
        Нет данных. Выберите диапазон дат.
      </td>
    </tr>

    <tr v-for="item in reportItemsLocalCopy" :key="item.id">
      <AuthorSalesReportGridRowReadOnly :item="item"/>
    </tr>

    <tr class="table-light" v-show="reportItemsLocalCopy.length">
      <td>Итого</td>
      <td></td>
      <td></td>
      <td></td>
      <td>{{ totalSales }} руб</td>
      <td></td>
      <td></td>
    </tr>
    </tbody>
  </table>
</template>

<script>
// import {mapState, mapGetters} from 'vuex';
import AuthorSalesReportGridRowReadOnly from "@/components/AuthorSalesReportGridRowReadOnly";

export default {
  name: 'AuthorSalesReportGrid',

  props: ['items', 'totalSales'],

  data() {
    return {
      reportItemsLocalCopy: [],
    };
  },

  components: {
    AuthorSalesReportGridRowReadOnly,
  },

  methods: {},

  watch: {
    items: {
      immediate: true,
      handler  : function ( newVal ) {
        this.reportItemsLocalCopy = newVal || [];
      }
    }
  },

  mounted() {
    this.reportItemsLocalCopy = this.items || [];
  }
}
</script>

