<template>
  <div class="row">
    <div class="col-4">
      <h4>Log In</h4>
      <form @submit.prevent="submit">
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label">Email address</label>
          <input v-model="form.email" type="email" class="form-control" id="exampleInputEmail1"
                 aria-describedby="emailHelp">
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label">Password</label>
          <input v-model="form.password" type="password" class="form-control" id="exampleInputPassword1">
        </div>

        <button type="submit" class="btn btn-primary">Submit</button>
      </form>

<!--      <div v-show="showError" class="alert alert-danger" role="alert">-->
<!--        {{ error }}-->
<!--      </div>-->

    </div>
  </div>
</template>

<script>

import {DO_LOGIN} from "@/store/auth/types";

export default {
  name: 'LoginView',

  data() {
    return {
      form     : {
        email   : '',
        password: '',
      },
      showError: false,
      error    : '',
    }
  },


  computed: {},

  watch: {},

  methods: {

    // ...mapActions( {
    //       doLogIn: DO_LOGIN
    //     }
    // ),

    async submit() {

      try {
        await this.$store.dispatch( `auth/${ DO_LOGIN }`, this.form );
        this.$router.push( "/sales-report" );
      }
      catch (err) {
        var genericErrorMessage = err?.message;
        var apiResponse = err?.response?.data;

        var errors = [
          genericErrorMessage,
          apiResponse?.error?.message
        ]

        this.showError = true;
        this.error = errors.join( ', ' );
      }
    },
  },

  mounted() {
  }
}

</script>
