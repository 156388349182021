<template>
  <td>{{ formatDate( item.userPurchase?.createdAt ) }}</td>
  <td>Студент&nbsp;#{{ item.userPurchase?.user?.id }}</td>
  <td>{{ item.userPurchase?.course?.author?.name }}</td>
  <td>{{ item.userPurchase?.course?.title }}</td>
  <td>{{ formatPrice( item.paymentTransaction?.total ) }}</td>
  <td>{{ item.userPurchase?.course?.progress }}</td>
  <td>
    <p v-for="e in item.errors" :key="e">{{ e.key }}</p>
  </td>
</template>

<script>


export default {
  name: 'AuthorSalesReportGridRowReadOnly',

  props: ['item'],

  methods: {
    formatDate( date ) {
      // TODO: move to filters
      if (!date) {
        return 'нет информации о дате'
      }

      return new Intl.DateTimeFormat( 'ru-RU', { dateStyle: 'long' } ).format( new Date( date ) )
    },
    formatPrice( number ) {
      // TODO: move to filters
      if (!number) {
        return 'нет информации о стоимости'
      }

      return new Intl.NumberFormat( 'ru-RU', { style: 'currency', currency: 'RUB' } ).format( number );
    },
  },

  mounted() {

  }
}
</script>
