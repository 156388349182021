import axios from "axios";
import store from '../store';

const API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

var apiClient = axios.create( {
    baseURL: API_BASE_URL,
    headers: { 'Content-Type': 'application/json' }
    // withCredentials: true
} );

apiClient.interceptors.request.use( ( config ) => {
    // Do something before request is sent

    const jwt = store.getters['auth/jwt'];
    if (jwt) {
        config.headers['Authorization'] = `Bearer ${ store.getters['auth/jwt'] }`;
    }

    return config;
}, ( error ) => {
    // Do something with request error
    return Promise.reject( error );
} );


export default apiClient;
