// import _ from "lodash";

// import apiClient from './api-client';

import {DO_LOGOUT, DO_LOGIN} from './types'
import auth from '@/services/auth';

const AuthStore = {
    namespaced: true,

    state: () => ({
        user: null,
        jwt : null
    }),

    mutations: {
        ['AUTH_ON_LOGIN']( state, payload ) {
            state.user = payload.user;
            state.jwt = payload.jwt;
        },

        ['AUTH_ON_LOGOUT']( state ) {
            state.user = null;
            state.jwt = null;
        },

        ['RESET_STATE']() {

        },

    },

    actions: {
        async [DO_LOGIN]( { commit }, payload ) {
            // make ajax with payload
            var data = await auth( payload );
            commit( 'AUTH_ON_LOGIN', { user: { id: data.user.id }, jwt: data.jwt } )
        },

        async [DO_LOGOUT]( { commit, dispatch } ) {
            commit( 'AUTH_ON_LOGOUT' );

            dispatch( 'auth/RESET_STATE', null, { root: true } );
            dispatch( 'main/RESET_STATE', null, { root: true } );
        },

        async ['RESET_STATE']( { commit } ) {
            commit( 'RESET_STATE' );
        },


    },

    getters: {
        isAuthenticated: state => !!state.jwt,

        user: state => state.user,
        jwt : state => state.jwt
    },
};


export default AuthStore;
