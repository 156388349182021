import {createRouter, createWebHistory} from 'vue-router'
import SalesView from '../views/SalesView.vue'
import LoginView from "@/views/LoginView";
import store from '../store';

const routes = [
    {
        path     : '/',
        name     : 'home',
        component: SalesView,
        meta     : { requiresAuth: true },
    },
    {
        path     : '/sales-report',
        name     : 'sales-report',
        component: SalesView,
        meta     : { requiresAuth: true },
    },
    {
        path     : '/login',
        name     : 'login',
        component: LoginView,
        meta     : { guest: true },
    },

]

const router = createRouter( {
    history: createWebHistory( process.env.BASE_URL ),
    routes
} );
router.beforeEach( ( to, from, next ) => {
    if (to.matched.some( ( record ) => record.meta.requiresAuth )) {
        if (store.getters[`auth/isAuthenticated`]) {
            next();
            return;
        }
        next( "/login" );
    } else {
        next();
    }
} );

router.beforeEach( ( to, from, next ) => {
    if (to.matched.some( ( record ) => record.meta.guest )) {
        if (store.getters[`auth/isAuthenticated`]) {
            next( "/" );
            return;
        }
        next();
    } else {
        next();
    }
} );
export default router
