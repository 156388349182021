import _ from 'lodash';

import authorsService from '@/services/authors-sales-report';

import {SALES_REPORT_ITEMS_LOADED, ALERT_ON_FAILURE} from './types';

const MainStore = {
    namespaced: true,

    state: () => ({
        salesReportItems: [],
        workItems       : [],
        alerts          : []
    }),

    getters: {
        sortedSalesReportItems( state ) {
            return _.sortBy( state.salesReportItems, 'id' );
        },

        alerts( state ) {
            return state.alerts;
        },

    },

    mutations: {
        [SALES_REPORT_ITEMS_LOADED]( state, payload ) {
            state.salesReportItems = (payload?.data || []);
        },

        [ALERT_ON_FAILURE]( state, payload ) {
            if (payload) {
                state.alerts.push( payload );
            } else {
                state.alerts = [];
            }

        },

        ['RESET_STATE']( state ) {
            state.salesReportItems = [];
            state.alerts = [];
        }
    },

    actions: {

        async ['RESET_STATE']( { commit } ) {
            commit( 'RESET_STATE' );
        },

        async getSalesReportItems( ctx, payload = {} ) {
            var { commit } = ctx;

            // try {
            var response = await authorsService.find( payload.dateRange );
            commit( SALES_REPORT_ITEMS_LOADED, response );
            // }
            // catch (err) {
            //     commit( ALERT_ON_FAILURE, err )
            // }

        }
    },
};


export default MainStore;
